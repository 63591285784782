import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TourFrame from "../components/TourFrame"

import BannerSalon from '../components/BannerSalon'
import BannerAgenda from '../components/BannerAgenda'
// import CardVideo from '../components/CardVideo'
import CardSalon from '../components/CardSalon'
import GaleriaLightbox from '../components/GaleriaLightbox'

import pic1 from '../images/galeria/j-1.jpg'
import pic2 from '../images/galeria/j-2.jpg'
import pic3 from '../images/galeria/j-3.jpg'
import pic4 from '../images/galeria/j-4.jpg'
import pic5 from '../images/galeria/j-5.jpg'
import pic6 from '../images/galeria/j-6.jpg'
import pic7 from '../images/galeria/j-7.jpg'
import pic8 from '../images/galeria/j-8.jpg'
import pic9 from '../images/galeria/j-9.jpg'
import pic10 from '../images/galeria/j-10.jpg'
import pic11 from '../images/galeria/j-11.jpg'
import pic12 from '../images/galeria/j-12.jpg'
import pic13 from '../images/galeria/j-13.jpg'
import pic14 from '../images/galeria/j-14.jpg'
import pic15 from '../images/galeria/j-15.jpg'

import banner from '../videos/jordi.mp4'
// import videoThumb1 from '../images/video-thumb-1.jpg'
// import videoThumb2 from '../images/video-thumb-2.jpg'
import salon2 from '../images/thumb-plaza-cataluna.jpg'
import check from '../images/i-check.svg'

const photos = [
	{ src: pic1, width: 4, height: 3 },
	{ src: pic2, width: 4, height: 3 },
	{ src: pic3, width: 4, height: 3 },
	{ src: pic4, width: 4, height: 3 },
	{ src: pic5, width: 4, height: 3 },
	{ src: pic6, width: 4, height: 3 },
	{ src: pic7, width: 4, height: 3 },
	{ src: pic8, width: 4, height: 3 },
	{ src: pic9, width: 4, height: 3 },
	{ src: pic10, width: 4, height: 3 },
	{ src: pic11, width: 4, height: 3 },
	{ src: pic12, width: 4, height: 3 },
	{ src: pic13, width: 4, height: 3 },
	{ src: pic14, width: 4, height: 3 },
	{ src: pic15, width: 4, height: 3 },
]

const PlazaCataluna = () => (
	<Layout>

		<SEO title="Salón Saint Jordi" />

		<BannerSalon
			titulo="Saint Jordi"
			capacidad="Hasta 300 personas"
			bgsrc={banner}
		>
			<div className="embed-responsive embed-responsive-16by9">
				<iframe title="Saint Jordi" className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/DPkYDkVdnUI?autoplay=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
			</div>
		</BannerSalon>

		<section className="info-salon-cont">
			<div className="acerca">
				<article>
					<h2>Acerca del salón</h2>
					<p>El salón Saint Jordi toma su nombre de la festividad española celebrada el 23 de abril.  A las mujeres que ese día asistían a la misa oficiada en la capilla de Sant Jordi de Palacio de la Generalidad de Cataluña, se les regalaba una rosa. La costumbre de que el hombre regale una rosa a la mujer se remonta al siglo XV. Algunas versiones hacen coincidir esto con la Feria de las Rosas que tenía lugar en Barcelona en aquella época.</p>
				</article>
			</div>
			<div className="amenidades">
				<div className="inner">
					<h3>Amenidades</h3>
					<ul>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Acabados de lujo</h4>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Lobby Independiente</h4>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Cuarto vestidor</h4>
							<p>Para uso de los novios o las quinceñaeras.</p>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Coordinadora</h4>
							<p>Tu evento incluye la ayuda de nuestra coordinadora el día de tu evento.</p>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Accesibilidad</h4>
							<p>Acceso para personas con discapacidad.</p>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Plano del salón</h4>
							<p>Teatro circular, u-shaped, boardroom y clase.</p>
							{/* <Link to="/" className="btn btn-simple pl-0 pr-0">Ver planta de piso</Link> */}
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Estacionamiento gratis</h4>
						</li>
					</ul>
				</div>
			</div>
		</section>

		<TourFrame source="https://my.matterport.com/show/?m=PYFbCvYpLto"></TourFrame>

		{/* <section className="videos-salon">
			<div className="heading-s1 text-center text-white mb-4">
				<h3 className="heading-titulo">Galería</h3>
				<p className="heading-caption">Vídeos de nuestro salón</p>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-5">
						<CardVideo
							buttonLabel="Boda Salón Cataluña"
							src={videoThumb1}
							youtubeid="9P6BkcZ65Rw"
							className="video-modal"
						></CardVideo>
					</div>
					<div className="col-md-6 mb-5">
						<CardVideo
							buttonLabel="Boda Salón Cataluña 2"
							src={videoThumb2}
							youtubeid="9P6BkcZ65Rw"
							className="video-modal"
						></CardVideo>
					</div>
				</div>
			</div>
		</section> */}

		<section className="galeria-fotos">
			<div className="heading-s1 text-center mb-4">
				<h3 className="heading-titulo">Galería</h3>
				<p className="heading-caption">Fotografías</p>
			</div>
			<div className="container">
				<GaleriaLightbox photos={photos}></GaleriaLightbox>
			</div>
		</section>

		<section className="interes-extra">
			<div className="heading-s1 text-center mb-4">
				<p className="heading-caption">También te puede interesar</p>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-5">
						<CardSalon
							titulo="Salón Plaza Cataluña"
							subtitulo="Capacidad: 500 personas"
							imgsrc={salon2}
							link="/plaza-cataluna"
						/>
					</div>
					<div className="col-md-6 mb-5">
						<div className="descargas pl-5">
							<small>Barcelona Catering</small>
							<p>Conoce el menú que tenemos para tu evento</p>
							<Link to="/catering" className="btn btn-outline">Ir a catering</Link>
						</div>
					</div>
				</div>
			</div>
		</section>

		<BannerAgenda paddingtop="pt-0"/>

	</Layout>
)

export default PlazaCataluna
